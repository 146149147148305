@tailwind base;
@tailwind components;
@tailwind utilities;

.add-space p {
  margin-bottom: 1rem;
}

.event-cover {
  min-height: '400px';
}

.editor-content p,
.editor-content ul,
.editor-content ol,
.editor-content blockquote,
.editor-content pre,
.editor-content hr {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  font-size: 1rem;
}
.editor-content figure {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}
.editor-content h1,
.editor-content h2,
.editor-content h3,
.editor-content h4,
.editor-content h5,
.editor-content h6 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.editor-content h2 {
  font-size: 1.5rem;
}

.editor-content [data-block='layout'] {
  display: flex;
  flex-direction: row;
  gap: 24px;
}
.editor-content.onecolumnSchedule {
  @apply bg-white dark:bg-gray-900 antialiased;
}
.editor-content.onecolumnSchedule .wrapper {
  @apply max-w-screen-xl px-4 py-8 mx-auto lg:px-6 sm:py-16 lg:py-24;
}
.editor-content.onecolumnSchedule .titleContainer {
  @apply max-w-3xl mx-auto text-center;
}
.editor-content.onecolumnSchedule .titleContainer h2 {
  @apply text-4xl font-extrabold leading-tight tracking-tight text-gray-900 dark:text-white;
}
.editor-content.onecolumnSchedule .row {
  @apply flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center;
}
.editor-content.onecolumnSchedule .row .time {
  @apply w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0;
}
.editor-content.onecolumnSchedule .row h3 {
  @apply text-lg font-semibold text-gray-900 dark:text-white;
}
.editor-content.onecolumnSchedule .innerContainer {
  @apply -my-4 divide-y divide-gray-200 dark:divide-gray-700;
}
.editor-content.onecolumnSchedule .outerContainer {
  @apply flow-root max-w-3xl mx-auto mt-8 sm:mt-12 lg:mt-16;
}

.editor-content.speakers {
  @apply bg-white dark:bg-gray-900 mt-8;
}

.editor-content.speakers .wrapper {
  @apply py-0 px-4 mx-auto max-w-screen-xl lg:px-6;
}

.editor-content.speakers .wrapper .titleContainer {
  @apply mx-auto max-w-screen-sm text-center mb-8;
}

.editor-content.speakers .wrapper .titleContainer h2 {
  @apply mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white;
}

.editor-content.speakers .wrapper .titleContainer p {
  @apply font-light text-gray-500 lg:mb-16 sm:text-xl dark:text-gray-400;
}

.editor-content.speakers .wrapper .container {
  @apply gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3;
}

.editor-content.speakers .wrapper .container .col figure {
  @apply bg-white rounded-lg border border-gray-200 shadow-sm dark:bg-gray-800 dark:border-gray-700;
  height: 230px;
}

.editor-content.speakers .wrapper .container .col figure img {
  @apply my-0;
}

.editor-content.speakers .wrapper .container .col figure img {
  @apply w-full h-full rounded-lg object-cover object-center;
}

.editor-content.speakers .wrapper .container .col .wrapper {
  @apply px-5 pb-5;
}

.editor-content.speakers .wrapper .container .col .wrapper h3 {
  @apply text-xl font-bold tracking-tight text-gray-900 dark:text-white;
}

.editor-content.speakers .wrapper .container .col .wrapper h4 {
  @apply text-gray-500;
}
.editor-content.speakers .wrapper .container .col .wrapper p {
  @apply mt-3 mb-4 font-light text-black dark:text-white;
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
